import './App.css';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import MainScreen from './screens/MainScreen';
import LoadJsonForm from './screens/LoadJsonForm';
import LanguageSelect from './screens/LanguageSelect';

import atelier from './img/atelier.jpg';
import deustotech from './img/DT-Logo-SMALL.png';
import sun2 from './img/sun2.png';
import cloud from './img/cloud.png';

import { MdOutlineArrowBackIosNew } from "react-icons/md";

import { useState, useEffect } from 'react';

import i18n from './i18n';
import { withTranslation } from 'react-i18next';

function App({t}) {
  const [showFormButtons, setShowFormButtons] = useState(false);
  const [showLoadJsonModal, setShowLoadJsonModal] = useState(false);

  const [gameData, setGameData] = useState([]);
    
  const loadLanguage = () => {
    var appLanguage = localStorage.getItem("lang");
    if(!appLanguage){
      appLanguage = "es";
    }
    return appLanguage;
  }

  var storedLanguage = loadLanguage();
  const [selectedLang, setSelectedLang] = useState(storedLanguage);

  useEffect(() => {
    i18n.changeLanguage(selectedLang);
  }, [selectedLang]);

  const handleOnChangeLanguage = (event) =>{
    var lang = event.target.value;
    setSelectedLang(lang);
    localStorage.setItem("lang", lang);
  }

  const refreshPage = () => {
    window.location.reload();
  }

  // EVENTS
  const onSaveEvent = (event) => {
    //console.log("onSaveEvent in App.js");
    //console.log(event);
    setGameDataEvent(event);
  }

  function setGameDataEvent(event){
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.eventData.some(item => item.id === event.id)){
      //console.log("Saved event exists");
      /*auxGameData.eventData.map((e) => {
        if(e.id === event.id){
          return event;
        } else {
          return e;
        }
      });*/
      let eventIndex = -1;
      for(var i = 0; i<auxGameData.eventData.length; i++){
        if(auxGameData.eventData[i].id === event.id){
          eventIndex = i;
          break;
        }
      }
      //console.log("eventIndex: " + eventIndex);
      if(eventIndex !== -1){
        auxGameData.eventData.splice(eventIndex, 1);
      }
    } 
    auxGameData.eventData.push(event);
    setGameData(auxGameData);
    console.log("Event updated in game data");
  }

  const onDeleteEvent = (event) => {
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.eventData.some(item => item.id === event.id)){
      //console.log("Deleted event exists");
      let eventIndex = -1;
      for(var i = 0; i<auxGameData.eventData.length; i++){
        if(auxGameData.eventData[i].id === event.id){
          eventIndex = i;
          break;
        }
      }
      if(eventIndex !== -1){
        auxGameData.eventData.splice(eventIndex, 1);
      }
    } 
    setGameData(auxGameData);
    console.log("Event deleted in game data");
  }

  // DILEMAS
  const onSaveDilema = (dilema) => {
    //console.log("onSaveEvent in App.js");
    //console.log(event);
    setGameDataDilema(dilema);
  }

  function setGameDataDilema(dilema){
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.dilemaData.some(item => item.id === dilema.id)){
      let dilemaIndex = -1;
      for(var i = 0; i<auxGameData.dilemaData.length; i++){
        if(auxGameData.dilemaData[i].id === dilema.id){
          dilemaIndex = i;
          break;
        }
      }
      
      if(dilemaIndex !== -1){
        auxGameData.dilemaData.splice(dilemaIndex, 1);
      }
    } 
    auxGameData.dilemaData.push(dilema);
    setGameData(auxGameData);
    console.log("Dilema updated in game data");
  }

  const onDeleteDilema = (dilema) => {
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.dilemaData.some(item => item.id === dilema.id)){
      let dilemaIndex = -1;
      for(var i = 0; i<auxGameData.dilemaData.length; i++){
        if(auxGameData.dilemaData[i].id === dilema.id){
          dilemaIndex = i;
          break;
        }
      }
      if(dilemaIndex !== -1){
        auxGameData.dilemaData.splice(dilemaIndex, 1);
      }
    } 
    setGameData(auxGameData);
    console.log("Dilema deleted in game data");
  }

  // DILEMA OPTIONS
  const onSaveDilemaOption = (option) => {
    //console.log("onSaveEvent in App.js");
    //console.log(event);
    setGameDataDilemaOption(option);
  }

  function setGameDataDilemaOption(option){
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.dilemaOptionData.some(item => item.id === option.id)){
      let dilemaOptionIndex = -1;
      for(var i = 0; i<auxGameData.dilemaOptionData.length; i++){
        if(auxGameData.dilemaOptionData[i].id === option.id){
          dilemaOptionIndex = i;
          break;
        }
      }
      
      if(dilemaOptionIndex !== -1){
        auxGameData.dilemaOptionData.splice(dilemaOptionIndex, 1);
      }
    } 
    auxGameData.dilemaOptionData.push(option);
    setGameData(auxGameData);
    console.log("Dilema option updated in game data");
  }

  const onDeleteDilemaOption = (option) => {
    let auxGameData = JSON.parse(JSON.stringify(gameData));
    if(auxGameData.dilemaOptionData.some(item => item.id === option.id)){
      let dilemaOptionIndex = -1;
      for(var i = 0; i<auxGameData.dilemaOptionData.length; i++){
        if(auxGameData.dilemaOptionData[i].id === option.id){
          dilemaOptionIndex = i;
          break;
        }
      }
      if(dilemaOptionIndex !== -1){
        auxGameData.dilemaOptionData.splice(dilemaOptionIndex, 1);
      }
    } 
    setGameData(auxGameData);
    console.log("Dilema option deleted in game data");
  }
  
  // JSON
  const exportJSON = () => {
    const fileData = JSON.stringify(gameData);
    const blob = new Blob([fileData], {type: "text/plain"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `atelier_sg_data.json`;
    link.href = url;
    link.click();
  }

  const loadJSON = () => {
    setShowLoadJsonModal(true);
    initializeJson();
  }

  const newJSON = () => {
    initializeJson();
  }

  function initializeJson(){
    setGameData({
      eventData: [],
      dilemaData: [],
      dilemaOptionData: []
    });
  }

  const onLoadJsonModalClose = () => {
    setShowLoadJsonModal(false);
    initializeJson();
  }

  const onLoadJson = () => {
    setShowLoadJsonModal(false);
  }

  const onJsonLoad = (event) => {
    var file = event.target.files[0];
    var fileReader = new FileReader();
    fileReader.onload = function (e) {
        var content = e.target.result;
        let json = JSON.parse(content); // parse json     
        setGameData(json);
    };
    
    fileReader.readAsText(file);
  }

  const reloadView = () => {
    window.location.reload();
  }

  return (
    <div className="App">
      <Navbar bg="light" expand="lg" fixed="top">
        <Container>
          <div>  
            <div onClick={reloadView} className="Brand-div">
              <img src={sun2} alt="" className='Brand-icon-left'></img> 
              <label className='You-decide-brand'>{t('You Decide')}</label>  
              <img src={cloud} alt="" className='Brand-icon-right'></img>   
            </div>
            <LanguageSelect value={selectedLang} onChange={handleOnChangeLanguage}></LanguageSelect>
          </div> 
          <FormButtons active={showFormButtons}></FormButtons>
        </Container>
      </Navbar>

      <div className="Main-screen">
        <MainScreen 
          gameData={gameData} 
          onClick={(event) => handleShowFormButtonsClick(event)}
          onSaveEvent={onSaveEvent}
          onDeleteEvent={onDeleteEvent}
          onSaveDilema={onSaveDilema}
          onDeleteDilema={onDeleteDilema}
          onSaveDilemaOption={onSaveDilemaOption}
          onDeleteDilemaOption={onDeleteDilemaOption}
          language={selectedLang}
        ></MainScreen>
      </div>

      <div className="App-footer">
        <img src={atelier} alt="Atelier" className='Atelier-icon'></img>
        <img src={deustotech} alt="DeustoTech" className='Deustotech-icon'></img>
      </div>

      <Modal show={showLoadJsonModal} size="xl" onHide={onLoadJsonModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('Load game data JSON')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadJsonForm onChange={(event) => onJsonLoad(event)}></LoadJsonForm>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onLoadJsonModalClose}>
                  {t("Close")}
                </Button>
                <Button variant="primary" onClick={onLoadJson}>
                  {t("Load")}
                </Button>
            </Modal.Footer>
        </Modal>  
    </div>
  );

  function FormButtons(props) {
    if(props.active) {
      return (
        <div>  
          <Button variant="outline-secondary" onClick={refreshPage}><MdOutlineArrowBackIosNew/></Button>{' '}
          {/*<Button variant="outline-warning" onClick={newJSON}>New JSON</Button>{' '}*/}
          <Button variant="outline-primary" onClick={exportJSON}>{t("Export JSON")}</Button>{' '}
        </div>
      )
    }
  }

  function handleShowFormButtonsClick(isNew) {
    setShowFormButtons(true);
    if(isNew) {
        newJSON();
    } else {
        loadJSON();
    }
  }
}

export default withTranslation()(App);
