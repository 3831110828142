import React, {useState} from "react";
import { BsPlusLg } from "react-icons/bs";

import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import EventDataTable from "./EventDataTable";
import DilemaDataTable from "./DilemaDataTable";
import DilemaOptionDataTable from "./DilemaOptionDataTable";
import EventForm from "./EventForm";
import DilemaForm from "./DilemaForm";
import DilemaOptionForm from "./DilemaOptionForm";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DataScreen(props) {
    const [showEventModal, setShowEventModal] = useState(false);
    const [showDilemaModal, setShowDilemaModal] = useState(false);
    const [showDilemaOptionModal, setShowDilemaOptionModal] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedDilema, setSelectedDilema] = useState(null);
    const [selectedDilemaOption, setSelectedDilemaOption] = useState(null);

    // EVENT FUNCTIONS
    const handleNewEventClick = () => {
        setSelectedEvent(null);
        setShowEventModal(true);
    }

    const onSaveEvent = () => {
        props.onSaveEvent(selectedEvent);
        setShowEventModal(false);
    }

    const onDeleteEvent = () => {
        let text = "Are you sure that you want to delete this event?";
        if (window.confirm(text) === true) {
            props.onDeleteEvent(selectedEvent);
            setShowEventModal(false);
        } 
        
    }

    const onEventModalClose = () => setShowEventModal(false);

    const onEventClick = (event) =>{
        //console.log("onEventClick");
        //console.log(event);
        setSelectedEvent(event);
        setShowEventModal(true);
    }

    const onChangeEvent = (event) => {
        //console.log("onChangeEvent");
        //console.log(event);
        setSelectedEvent(event);
    }

    // DILEMA FUNCTIONS    
    const handleNewDilemaClick = () => {
        setSelectedDilema(null);
        setShowDilemaModal(true);    
    }

    const onSaveDilema = () => {
        props.onSaveDilema(selectedDilema);
        setShowDilemaModal(false);
    }

    const onDeleteDilema = () => {
        let text = "Are you sure that you want to delete this dilema?";
        if (window.confirm(text) === true) {
            props.onDeleteDilema(selectedDilema);
            setShowDilemaModal(false);
        }         
    }

    const onDilemaModalClose = () => setShowDilemaModal(false);

    const onDilemaClick = (dilema) =>{
        setSelectedDilema(dilema);
        setShowDilemaModal(true);
    }

    const onChangeDilema = (dilema) => {
        setSelectedDilema(dilema);
    }

    // DILEMA OPTION FUNCTIONS
    const handleNewDilemaOptionClick = () => {
        setSelectedDilemaOption(null);
        setShowDilemaOptionModal(true);
    }

    const onSaveDilemaOption = () => {
        props.onSaveDilemaOption(selectedDilemaOption);
        setShowDilemaOptionModal(false);
    }

    const onDeleteDilemaOption = () => {
        let text = "Are you sure that you want to delete this dilema option?";
        if (window.confirm(text) === true) {
            props.onDeleteDilemaOption(selectedDilemaOption);
            setShowDilemaOptionModal(false);
        } 
        
    }
    const onDilemaOptionModalClose = () => setShowDilemaOptionModal(false);

    const onDilemaOptionClick = (option) =>{
        setSelectedDilemaOption(option);
        setShowDilemaOptionModal(true);
    }

    const onChangeDilemaOption = (option) => {
        setSelectedDilemaOption(option);
    }
        
                
    return (
        <div className="App-form-container-div">
            <div className="App-form-div">      
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{i18next.t("Events")}</Accordion.Header>
                        <Accordion.Body>
                            <div className="Events-actions-div">
                                <Button variant="outline-warning" className="Icon-button" onClick={handleNewEventClick}><BsPlusLg/> {i18next.t("New event")}</Button>{' '}
                            </div>  
                            <EventDataTable 
                                data={props.gameData.eventData} 
                                onClick={onEventClick} 
                                language={props.language}
                                tableTitle={i18next.t("Title")}
                            ></EventDataTable>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>{i18next.t("Dilemas")}</Accordion.Header>
                        <Accordion.Body>
                            <div className="Events-actions-div">
                                <Button variant="outline-warning" className="Icon-button" onClick={handleNewDilemaClick}><BsPlusLg/> {i18next.t("New dilema")}</Button>{' '}
                            </div> 
                            <DilemaDataTable 
                                data={props.gameData.dilemaData} 
                                onClick={onDilemaClick} 
                                language={props.language}
                                tableTitle={i18next.t("Title")}
                            ></DilemaDataTable>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>{i18next.t("Dilema's options")}</Accordion.Header>
                        <Accordion.Body>
                            <div className="Events-actions-div">
                                <Button variant="outline-warning" className="Icon-button" onClick={handleNewDilemaOptionClick}><BsPlusLg/> {i18next.t("New dilema option")}</Button>{' '}
                            </div> 
                            <DilemaOptionDataTable 
                                data={props.gameData.dilemaOptionData} 
                                onClick={onDilemaOptionClick} 
                                language={props.language}
                                tableChallenge={i18next.t("Challenge")}
                            ></DilemaOptionDataTable>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>  

                <Modal show={showEventModal} size="xl" onHide={onEventModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("Event data")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EventForm event={selectedEvent} onChange={onChangeEvent}></EventForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={onDeleteEvent}>
                            {i18next.t("Delete")}
                        </Button>
                        <Button variant="secondary" onClick={onEventModalClose}>
                            {i18next.t("Close")}
                        </Button>
                        <Button variant="primary" onClick={onSaveEvent}>
                            {i18next.t("Save")}
                        </Button>
                    </Modal.Footer>
                </Modal>            

                <Modal show={showDilemaModal} size="xl" onHide={onDilemaModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("Dilema data")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DilemaForm dilema={selectedDilema} onChange={onChangeDilema}></DilemaForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={onDeleteDilema}>
                            {i18next.t("Delete")}
                        </Button>
                        <Button variant="secondary" onClick={onDilemaModalClose}>
                            {i18next.t("Close")}
                        </Button>
                        <Button variant="primary" onClick={onSaveDilema}>
                            {i18next.t("Save")}
                        </Button>
                    </Modal.Footer>
                </Modal>     

                <Modal show={showDilemaOptionModal} size="xl" onHide={onDilemaOptionModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i18next.t("Dilema option data")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DilemaOptionForm option={selectedDilemaOption} onChange={onChangeDilemaOption}></DilemaOptionForm>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={onDeleteDilemaOption}>
                            {i18next.t("Delete")}
                        </Button>
                        <Button variant="secondary" onClick={onDilemaOptionModalClose}>
                            {i18next.t("Close")}
                        </Button>
                        <Button variant="primary" onClick={onSaveDilemaOption}>
                            {i18next.t("Save")}
                        </Button>
                    </Modal.Footer>
                </Modal>     
            </div>
        </div>
    )  
    
}

export default withTranslation()(DataScreen);