import React, {useState} from "react";

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import environment from './../img/forest.png';
import economy from './../img/economy.png';
import social from './../img/crowd.png';
import innovation from './../img/innovation.png';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function EventForm(props){        
    let existingEvent = false;
    const [gameEvent, setGameEvent] = useState({
        id: generateId(),
        title: {
            es: "",
            eus: "",
            en: ""
        }, 
        description: {
            es: "",
            eus: "",
            en: ""
        }, 
        points: {
            environment: "0",
            society: "0",
            economy: "0",
            innovation: "0"
        }
    });

    function generateId(){
        let date = new Date().toISOString();
        let id = "event_" + date;
        return id;
    }

    /*if(props.event){
        setGameEvent(JSON.parse(JSON.stringify(props.event)));        
    }*/

    React.useEffect(() => {
        if(props.event != null){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingEvent = true;
            setGameEvent(props.event);
        }
    }, [props.event]);

    React.useEffect(() => {
        if(!existingEvent){
            props.onChange(gameEvent);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingEvent = false;
        }
    }, [gameEvent, props]);

    const onEusTitleChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              title: {
                ...current.title,
      
                // 👇️ override value for nested country property
                eus: event.target.value,
              },
            };
        });        
    }

    const onEsTitleChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              title: {
                ...current.title,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnTitleChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              title: {
                ...current.title,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    const onEnvironmentPointsChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                environment: event.target.value,
              },
            };
        });
    }

    const onEconomyPointsChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                economy: event.target.value,
              },
            };
        });
    }

    const onSocietyPointsChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                society: event.target.value,
              },
            };
        });
    }

    const onInnovationPointsChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                innovation: event.target.value,
              },
            };
        });
    }

    const onEusDescriptionChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                eus: event.target.value,
              },
            };
        });        
    }

    const onEsDescriptionChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnDescriptionChange = (event) => {
        setGameEvent(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("Title")}</Form.Label>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Euskera"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Titulua euskeraz" name="title.eus" value={gameEvent.title.eus} onChange={onEusTitleChange}/>
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Español"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Título en español" name="title.es" value={gameEvent.title.es} onChange={onEsTitleChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="English"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Title in english" name="title.en" value={gameEvent.title.en} onChange={onEnTitleChange} />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("Punctuations")}</Form.Label>
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={environment} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Environment")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameEvent.points.environment} onChange={onEnvironmentPointsChange}/>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={economy} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Economy")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameEvent.points.economy} onChange={onEconomyPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={social} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Society")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameEvent.points.society} onChange={onSocietyPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={innovation} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Innovation")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameEvent.points.innovation} onChange={onInnovationPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Form.Group>

            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Description")}</Form.Label>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Euskera"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Deskribapena euskeraz" name="description.eus" value={gameEvent.description.eus} onChange={onEusDescriptionChange}/>
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Español"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Descripción en español" name="description.es" value={gameEvent.description.es} onChange={onEsDescriptionChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="English"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Description in english" name="description.en" value={gameEvent.description.en} onChange={onEnDescriptionChange} />
                </FloatingLabel>
            </Form.Group>
        </>
    )
}

export default withTranslation()(EventForm);