import React from "react";

import Form from 'react-bootstrap/Form';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function LoadJsonForm(props){
    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("JSON file")}</Form.Label>
                <Form.Control type="file" placeholder="JSON file" onChange={(event) => props.onChange(event)}/>        
            </Form.Group>
        </>
    )
}

export default withTranslation()(LoadJsonForm);