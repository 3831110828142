import React, {useState} from "react";
import Button from 'react-bootstrap/Button';

import DataScreen from "./DataScreen";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function MainScreen(props){
    const [view, setView] = useState("intro");

    //console.log(view);

    const onSaveEvent = (event) => {
        props.onSaveEvent(event);
    }

    const onDeleteEvent = (event) => {
        props.onDeleteEvent(event);
    }

    const onSaveDilema = (dilema) => {
        props.onSaveDilema(dilema);
    }

    const onDeleteDilema = (dilema) => {
        props.onDeleteDilema(dilema);
    }

    const onSaveDilemaOption = (option) => {
        props.onSaveDilemaOption(option);
    }

    const onDeleteDilemaOption = (option) => {
        props.onDeleteDilemaOption(option);
    }

    if(view === "intro"){
        return (        
            <div className="Atelier-info">
                <h4>{i18next.t("This is Atelier H2020 project's serious game data manager")}</h4>
                <p className="Atelier-info-text">
                {i18next.t("The objective of this application is to generate the JSON file")}
                </p>
                <div>
                    <Button variant="outline-warning" onClick={(event) => handleClick(true)}>{i18next.t("Create new JSON")}</Button>{' '}
                    <Button variant="outline-primary" onClick={(event) => handleClick(false)}>{i18next.t("Load existing JSON")}</Button>{' '}
                </div>
            </div>        
        );
    } else {
        return (
            <DataScreen 
                gameData={props.gameData} 
                onSaveEvent={onSaveEvent} 
                onDeleteEvent={onDeleteEvent}
                onSaveDilema={onSaveDilema} 
                onDeleteDilema={onDeleteDilema}
                onSaveDilemaOption={onSaveDilemaOption} 
                onDeleteDilemaOption={onDeleteDilemaOption}
                language={props.language}
            ></DataScreen>
        )
    }

    function handleClick(isNew){
        setView("data");
        props.onClick(isNew);
    }
}

export default withTranslation()(MainScreen);