import React, {useState} from "react";
import { SketchPicker } from 'react-color';

import challengeData from './../data/challenges.json';

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DilemaForm(props){
    const loadLanguage = () => {
        var appLanguage = localStorage.getItem("lang");
        if(!appLanguage){
          appLanguage = "es";
        }
        return appLanguage;
    }

    var storedLanguage = loadLanguage();

    const getLangChallengeData = (lang) => {
        let langChallenges = [];

        for(var i=0; i<challengeData.length; i++){
            if(challengeData[i].language === lang){
                langChallenges = challengeData[i].challenges;
                break;
            }
        }

        return langChallenges;
    }

    const langChallengeData = getLangChallengeData(storedLanguage);

    const getChallengeData = (challengeId) =>{
        let selectedChallengeData = {
            id: challengeId,
            name: {
                eus: "",
                en: "",
                es: ""
            }
        };
        for(var i=0; i<challengeData.length; i++){
            let lang = challengeData[i].language;
            for(var j=0; j<challengeData[i].challenges.length; j++){
                let challenge = challengeData[i].challenges[j];
                if(challenge.id === challengeId){
                    if(lang === "eus"){
                        selectedChallengeData.name.eus = challenge.name;
                    } else if(lang === "es"){
                        selectedChallengeData.name.es = challenge.name;
                    } else if(lang === "en"){ 
                        selectedChallengeData.name.en = challenge.name;
                    }
                }
            }
        }

        return selectedChallengeData;
    }

    let existingDilema = false;
    const [gameDilema, setGameDilema] = useState({
        id: generateId(),
        challenge: getChallengeData("1"),
        color: "#fff",
        title: {
            es: "",
            eus: "",
            en: ""
        }, 
        description: {
            es: "",
            eus: "",
            en: ""
        }
    });

    function generateId(){
        let date = new Date().toISOString();
        let id = "dilema_" + date;
        return id;
    }

    React.useEffect(() => {
        if(props.dilema != null){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingDilema = true;
            setGameDilema(props.dilema);
        }
    }, [props.dilema]);

    React.useEffect(() => {
        if(!existingDilema){
            props.onChange(gameDilema);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingDilema = false;
        }
    }, [gameDilema, props]);

    const onChallengeChange = (event) => {
        let challengeId = event.target.value;
        let dilemaChallenge = getChallengeData(challengeId);        

        setGameDilema(current => {
            return {
                ...current,
                challenge: dilemaChallenge
            };
        });        
    }  
    
    const onColorChange = (color) => { 
        setGameDilema(current => {
            return {
                ...current,
                color: color.hex
            };
        });        
    }  

    const onEusTitleChange = (event) => {
        setGameDilema((current) => {
            // 👇️ using spread syntax (...)
      
            return {
                ...current,
                title: {
                  ...current.title,
        
                  // 👇️ override value for nested country property
                  eus: event.target.value,
                },
            };
        });        
    }

    const onEsTitleChange = (event) => {
        setGameDilema(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              title: {
                ...current.title,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnTitleChange = (event) => {
        setGameDilema(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              title: {
                ...current.title,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    const onEusDescriptionChange = (event) => {
        setGameDilema(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                eus: event.target.value,
              },
            };
        });        
    }

    const onEsDescriptionChange = (event) => {
        setGameDilema(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnDescriptionChange = (event) => {
        setGameDilema(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    return (
        <>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Challenge")}</Form.Label>
                <Form.Select onChange={onChallengeChange} value={gameDilema.challenge.id}>
                    {langChallengeData.map(item => {
                        return (<option key={item.id} value={item.id}>{item.name}</option>);                        
                    })}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Color")}</Form.Label>
                {/*<Form.Control type="text" placeholder="Color" name="color" value={gameDilema.color} onChange={onColorChange} />*/}
                <SketchPicker
                    color={ gameDilema.color }
                    onChangeComplete={ onColorChange }
                />
            </Form.Group>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Title")}</Form.Label>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Euskera"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Titulua euskeraz" name="title.eus" value={gameDilema.title.eus} onChange={onEusTitleChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Español"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Título en español" name="title.es" value={gameDilema.title.es} onChange={onEsTitleChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="English"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Title in english" name="title.en" value={gameDilema.title.en} onChange={onEnTitleChange} />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Description")}</Form.Label>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Euskera"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Deskribapena euskeraz" name="description.eus" value={gameDilema.description.eus} onChange={onEusDescriptionChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="Español"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Descripción en español" name="description.es" value={gameDilema.description.es} onChange={onEsDescriptionChange} />
                </FloatingLabel>
                <FloatingLabel
                    controlId="floatingInput"
                    label="English"
                    className="mb-1"
                >
                    <Form.Control type="text" placeholder="Description in english" name="description.en" value={gameDilema.description.en} onChange={onEnDescriptionChange} />
                </FloatingLabel>
            </Form.Group>

        </>
    )
}

export default withTranslation()(DilemaForm);