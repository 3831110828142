import React from "react";

function LanguageSelect(props){
    return (
        <div className="Language-select-div">
            <select className="Language-select" onChange={(event) => props.onChange(event)} value={props.value}>
                <option value="en">en</option>
                <option value="eus">eus</option>
                <option value="es">es</option>
            </select>
        </div>
    )
}

export default LanguageSelect;