import React, {useState} from "react";

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import environment from './../img/forest.png';
import economy from './../img/economy.png';
import social from './../img/crowd.png';
import innovation from './../img/innovation.png';

import challengeData from './../data/challenges.json';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DilemaOptionForm(props){
    const loadLanguage = () => {
        var appLanguage = localStorage.getItem("lang");
        if(!appLanguage){
          appLanguage = "es";
        }
        return appLanguage;
    }

    var storedLanguage = loadLanguage();

    const getLangChallengeData = (lang) => {
        let langChallenges = [];

        for(var i=0; i<challengeData.length; i++){
            if(challengeData[i].language === lang){
                langChallenges = challengeData[i].challenges;
                break;
            }
        }

        return langChallenges;
    }

    const langChallengeData = getLangChallengeData(storedLanguage);
    
    const getChallengeData = (challengeId) =>{
        let selectedChallengeData = {
            id: challengeId,
            name: {
                eus: "",
                en: "",
                es: ""
            }
        };
        for(var i=0; i<challengeData.length; i++){
            let lang = challengeData[i].language;
            for(var j=0; j<challengeData[i].challenges.length; j++){
                let challenge = challengeData[i].challenges[j];
                if(challenge.id === challengeId){
                    if(lang === "eus"){
                        selectedChallengeData.name.eus = challenge.name;
                    } else if(lang === "es"){
                        selectedChallengeData.name.es = challenge.name;
                    } else if(lang === "en"){ 
                        selectedChallengeData.name.en = challenge.name;
                    }
                }
            }
        }

        return selectedChallengeData;
    }

    let existingDilemaOption = false;
    const [gameDilemaOption, setGameDilemaOption] = useState({
        id: generateId(),
        challenge: getChallengeData("1"),
        description: {
            es: "",
            eus: "",
            en: ""
        },
        information: {
            es: "",
            eus: "",
            en: ""
        }, 
        points: {
            environment: "0",
            society: "0",
            economy: "0",
            innovation: "0"
        } 
    });

    function generateId(){
        let date = new Date().toISOString();
        let id = "option_" + date;
        return id;
    }

    React.useEffect(() => {
        if(props.option != null){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingDilemaOption = true;
            setGameDilemaOption(props.option);
        }
    }, [props.dilema]);

    React.useEffect(() => {
        if(!existingDilemaOption){
            props.onChange(gameDilemaOption);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            existingDilemaOption= false;
        }
    }, [gameDilemaOption, props]);

    const onChallengeChange = (event) => {
        let challengeId = event.target.value;
        let dilemaChallenge = getChallengeData(challengeId);        

        setGameDilemaOption(current => {
            return {
                ...current,
                challenge: dilemaChallenge
            };
        });        
    }

    const onEusDescriptionChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                eus: event.target.value,
              },
            };
        });        
    }

    const onEsDescriptionChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnDescriptionChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              description: {
                ...current.description,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    const onEusInformationChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              information: {
                ...current.information,
      
                // 👇️ override value for nested country property
                eus: event.target.value,
              },
            };
        });        
    }

    const onEsInformationChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              information: {
                ...current.information,
      
                // 👇️ override value for nested country property
                es: event.target.value,
              },
            };
        });
    }

    const onEnInformationChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              information: {
                ...current.information,
      
                // 👇️ override value for nested country property
                en: event.target.value,
              },
            };
        });
    }

    const onEnvironmentPointsChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                environment: event.target.value,
              },
            };
        });
    }

    const onEconomyPointsChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                economy: event.target.value,
              },
            };
        });
    }

    const onSocietyPointsChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                society: event.target.value,
              },
            };
        });
    }

    const onInnovationPointsChange = (event) => {
        setGameDilemaOption(current => {
            // 👇️ using spread syntax (...)
      
            return {
              ...current,
              points: {
                ...current.points,
      
                // 👇️ override value for nested country property
                innovation: event.target.value,
              },
            };
        });
    }

    return (
        <>
            <Form.Group className="mb-3" controlId="">
                <Form.Label className="Form-label">{i18next.t("Challenge")}</Form.Label>
                <Form.Select onChange={onChallengeChange} value={gameDilemaOption.challenge.id}>
                    {langChallengeData.map(item => {
                        return (<option key={item.id} value={item.id}>{item.name}</option>);                        
                    })}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("Description")}</Form.Label>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Euskera"
                        className="mb-1"
                    >
                        <Form.Control type="text" placeholder="Deskribapena euskeraz" name="description.eus" value={gameDilemaOption.description.eus} onChange={onEusDescriptionChange} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Español"
                        className="mb-1"
                    >
                        <Form.Control type="text" placeholder="Descripción en español" name="description.es" value={gameDilemaOption.description.es} onChange={onEsDescriptionChange} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="English"
                        className="mb-1"
                    >
                    <Form.Control type="text" placeholder="Description in english" name="description.eus" value={gameDilemaOption.description.en} onChange={onEnDescriptionChange} />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("Information")}</Form.Label>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Euskera"
                        className="mb-1"
                    >
                        <Form.Control type="text" placeholder="Informazioa euskeraz" value={gameDilemaOption.information.eus} onChange={onEusInformationChange} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Español"
                        className="mb-1"
                    >
                        <Form.Control type="text" placeholder="Información en español" value={gameDilemaOption.information.es} onChange={onEsInformationChange} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="English"
                        className="mb-1"
                    >
                    <Form.Control type="text" placeholder="Information in english" value={gameDilemaOption.information.en} onChange={onEnInformationChange} />
                </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className="Form-label">{i18next.t("Punctuations")}</Form.Label>
                <Container>
                    <Row>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={environment} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Environment")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameDilemaOption.points.environment} onChange={onEnvironmentPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={economy} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Economy")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameDilemaOption.points.economy} onChange={onEconomyPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={social} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Society")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameDilemaOption.points.society} onChange={onSocietyPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col sm={3}>   
                                    <Image fluid="true" src={innovation} className="Scope-img"></Image>
                                </Col>
                                <Col sm={9}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label={i18next.t("Innovation")}
                                        className="mb-1"
                                    >
                                        <Form.Control type="number" value={gameDilemaOption.points.innovation} onChange={onInnovationPointsChange} />
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Form.Group>
        </>
    )
}

export default withTranslation()(DilemaOptionForm);