import React, { useEffect, useRef } from "react";
import $ from 'jquery';

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function DilemaOptionDataTable(props){
    $.DataTable = require('datatables.net');
    const tableRef = useRef()
 
    useEffect(() => {
        //console.log(tableRef.current)
        const table = $(tableRef.current).DataTable(
            {
                data: props.data,
                columns: [
                    { title: "Id", data: "id", visible: false},
                    { title: props.tableChallenge, data: "challenge.name."+props.language},
                    { title: i18next.t("Description"), data: "description."+props.language},
                    { title: i18next.t("Environment points"), data: "points.environment"},
                    { title: i18next.t("Economical points"), data: "points.economy"},
                    { title: i18next.t("Social points"), data: "points.society"},
                    { title: i18next.t("Innovation points"), data: "points.innovation"},
                ],
                columnDefs: [{
                    "defaultContent": "-",
                    "targets": "_all"
                }],
                lengthMenu: [[5, 10, 25, 50, 100, -1], [5, 10, 25, 50, 100, '...']],
                autoWidth: true,
                destroy: true  // I think some clean up is happening here                
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            //console.log("Table destroyed")
            table.destroy()
        }
    },[props.data, props.language, props.tableChallenge]);    

    return (
        <div>
            <table className="display" width="100%" ref={ tableRef } onClick={(event) => handleTableClick(event)}></table>
        </div>
    );

    function handleTableClick(event) {
        if(event.target.localName === "td"){            
            var currentRow = $(event.target).closest("tr");
            var rowData =  $(tableRef.current).DataTable().row(currentRow).data();
            if(rowData){
                var optionId = rowData.id;
                console.log("Dilema option ID: " + optionId);
                let dilemaOptionData = getClickedDilemaOptionData(optionId);
                props.onClick(dilemaOptionData);
            }
        }
    }

    function getClickedDilemaOptionData(optionId){
        let option;
        for(var i=0; i<props.data.length; i++){
            if(props.data[i].id === optionId){
                option = props.data[i];
                break;
            }
        }
        return option;
    }
    
}

export default withTranslation()(DilemaOptionDataTable);